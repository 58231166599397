import { createContext, useCallback, useContext } from "react";
import {DefaultRole} from "components/types/Role";

export interface LoginUser {
  sub: string;
  id: string;
  loginId: string;
  name: string;
  companyId: string;
  authenticated: boolean;
  authorities: Array<string>;
  roles: Array<DefaultRole>;
};

export const UserContext = createContext<LoginUser>(null);

export const useUserContext = () => {
  const user = useContext(UserContext);

  const hasAuthority = useCallback(
    (authorities: Array<string>) => {
      return authorities?.some((authority) =>
        user?.authorities?.includes(authority)
      );
    },
    [user]
  );

  return {
    user,
    hasAuthority,
  };
};
