import {AxiosResponse} from "axios";
import { useEffect } from "react";
import { objCookies } from "shared/utils/CommonUtil";
import useSWR from "swr";
import {apis} from "../http/http-service";

export interface User {
    id: number;
    name: string;
    loginId: string;
    companyId: string;
    companyName: string;
    authorities: Array<string>;
}

const userFetcher = (...args: any[]) =>
    apis.get<User, AxiosResponse<User>>(args[0]).then((res) => res.data);

// deprecated
export function useUser({redirectTo = ""}) {
    const {data: user, error} = useSWR(`/api/user`, userFetcher);

    useEffect(() => {
        if (user) {
          objCookies.set({ name: "loginId", value: user.loginId, maxAge: "session" });
        }
      }, [user]);

    return {
        user,
        isLoading: !error && !user,
        isError: error,
    };
};
