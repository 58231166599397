import useOtpAuth from "hooks/useOtpAuth";
import { useRouter } from "next/router";
import { useEffect } from "react";
import checkOtpAuth from "states/googleOtp";

interface AuthProviderProps {
  children: React.ReactNode;
}
const AdminAuthProvider = ({ children }: AuthProviderProps) => {
  const { pathname, push } = useRouter();
  const { isOtpAuth } = useOtpAuth();

  const isAdminPath = pathname.includes("/smedu");

  useEffect(() => {
    if (isOtpAuth) {
      return;
    }
    if (isAdminPath && !checkOtpAuth()) {
      push("/otp");
    }
  }, [pathname]);
  return <>{children}</>;
};

export default AdminAuthProvider;
