import { COOKIE_NAME } from "constants/cookie";
import { useCallback, useMemo } from "react";
import { objCookies } from "shared/utils/CommonUtil";
import { setOtpAuth } from "states/googleOtp";
import useGoogleOtpState from "./useGoogleOtpState";

const useOtpAuth = () => {
  const { isOtpAuthState, setOtpAuthState } = useGoogleOtpState();

  const isOtpAuth = useMemo(() => {
    const otpAuthCookie = objCookies.get({ name: COOKIE_NAME.OTP_AUTH });
    if (otpAuthCookie) {
      if (isOtpAuthState === false) {
        setOtpAuthState(true);
      }
      return true;
    }
    return isOtpAuthState;
  }, [isOtpAuthState]);

  const setOthAuth = useCallback(() => {
    objCookies.set({
      name: COOKIE_NAME.OTP_AUTH,
      value: true,
      maxAge: "session",
    });
    setOtpAuth(true);
  }, []);

  return { isOtpAuth, setOthAuth };
};

export default useOtpAuth;
