import {createMuiTheme, makeStyles, Theme} from "@material-ui/core/styles";
import {palette} from '@material-ui/system';

export const kirdTheme = createMuiTheme({
  // breakpoints: {
  //   values: {
  //     lg: 1388,
  //   }
  // },
  palette: {
    primary: {
      main: "#3c89f9",
      dark: '#3479dc',
      light: '#ebf3ff',
    },
    // primary: {
    //   main: '#3c89f9',
    //   main50: '#9cc3fe',
    //   main10: '#ebf3ff',
    // },
    // semantic: {
    //   purple: '#7f56fd',
    //   purple60: '#b296ff',
    //   purple15: '#ece5ff',
    //   pink: '#ff647c',
    //   pink60: '#ffa1af',
    //   pink15: '#ffe8ec',
    //   robin: '#5bdbff',
    //   robin60: '#99eaff',
    //   robin15: '#e6faff',
    //   orange: '#ff9252',
    //   orange60: '#ffbe94',
    //   orange15: '#ffefe4',
    //   aqua: '#00d4d3',
    //   aqua60: '#5fe5e6',
    //   aqua15: '#d8f9f9',
    //   mustard: '#ffc864',
    //   mustard60: '#ffdf9e',
    //   mustard15: '#fff7e7',
    // },
    // newtral: {
    //   black70: '#4a4a4a',
    //   black50: '#7f7f7f',
    //   black30: '#b2b2b2',
    //   black10: '#e5e5e5',
    //   black5: '#f2f2f2',
    // },
  }, // end of palette
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)", // 8 --> 1
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)", // 9 --> 2
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)", // 24 --> 17
  ],
  typography: {
    fontFamily: ["NotoSansCJKkr", "Rubik"].join(","),
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    // marginTop: 0,
    // marginLeft: 0,
    // marginBottom: 16,

    // title: {
    //   marginRight: 25,
    //   marginBottom: 4,
    //   fontSize: 60,
    //   lineHeight: 1.33,
    //   letterSpacing: -0.79,
    //   fontWeight: 500,
    // },
    h1: {
      fontSize: "4.61rem",
      lineHeight: 1.47,
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.777rem",
      lineHeight: 1.5,
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.33rem",
      lineHeight: 1.67,
      fontWeight: 500,
    },
    h4: {
      fontSize: "1rem",
      lineHeight: 1.78,
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.78,
      fontWeight: 400,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: 1.78,
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      contained: {
        color: 'rgb(80, 80, 80)',
        borderRadius: 3,
        boxShadow: '0 2px 3px 1px rgba(100, 100, 100, .3)',
      },
    },
    MuiTable: {
      root: {
        width: '100%',
        maxWidth: '100%',
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: 'rgb(230, 230, 230)',
      }
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(214,214,214, .2)'
        }
      },
      head: {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '1em',
      },
      body: {
        fontSize: '0.9125em',
        lineHeight: 1.22857143,
        paddingTop: 14,
        paddingBottom: 14,
      }
    }

  }
});

export const tailwind = {
  error: 'ml-4 text-error text-md font-normal text-error',
  'btnContained': 'p-4 text-lg text-center bg-gray-200 rounded',
};
