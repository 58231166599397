import axios from 'axios';

export const apis = axios.create({
  // timeout: 2500,
});

// apis.defaults.headers.post['Content-Type'] = 'application/json';
// apis.defaults.headers.put['Content-Type'] = 'application/json';
// apis.defaults.headers.delete['Content-Type'] = 'application/json';

apis.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // // 401: Unauthorized
    // if (error.response.status === 401) {
    //   location.href = '/login';
    // }
    // // 403: Forbidden
    // else if (error.response.status === 403) {
    //   location.href = '/';
    // }

    return Promise.reject(error);
  }
);
