import React, {useRef, useState, createContext, useContext} from "react";
import { ConfirmationDialog, ConfirmationOptions } from "./ConfirmationDialog";

const ConfirmationServiceContext = createContext<
  (options: ConfirmationOptions) => Promise<void>
  >(Promise.reject);

export const useConfirmation = () =>
  useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: any) => {

  const [open, setOpen] = useState<boolean>(false);

  const [
    confirmationState,
    setConfirmationState
  ] = useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    rejectInfo: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    setOpen(true);
    return new Promise<void>((resolve, rejectInfo) => {
      awaitingPromiseRef.current = { resolve, rejectInfo };
    });
  };

  const handleClose = () => {
    if (confirmationState!.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.rejectInfo();
    } else {
      // promise catch를 안할 경우는 promise 를 날려주자!
      awaitingPromiseRef.current = undefined;
    }

    setOpen(false);
    // setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setOpen(false);
    // setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
