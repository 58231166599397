import { useReactiveVar } from "@apollo/client";
import checkOtpAuth from "states/googleOtp";

const useGoogleOtpState = () => {
  const isOtpAuthState = useReactiveVar(checkOtpAuth);

  const setOtpAuthState = (value: boolean) => {
    checkOtpAuth(value);
  };

  return {
    isOtpAuthState,
    setOtpAuthState,
  };
};

export default useGoogleOtpState;
