import React from "react";
import "../styles/globals.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { ConfirmationServiceProvider } from "../components/common/confirmation/ConfirmationService";
import { kirdTheme } from "../theme/kird-theme";
import { appWithTranslation } from "next-i18next";
import { SnackbarProvider } from "notistack";
import { CssBaseline, Grow } from "@material-ui/core";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { parseCookies } from "nookies";
import { createUploadLink } from "apollo-upload-client";
// Material-UI pickers
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import koLocale from "date-fns/locale/ko";
// for fullcalendar
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Head from "next/head";
import { UserContext } from "shared/user-context";
import { useUser } from "shared/api/user";
import { useRouter } from "next/router";
import AdminAuthProvider from "../components/smedu/AdminAuthProvider";

const uri = "/api/graphql";

const uploadLink = createUploadLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const cookies = parseCookies();
  return {
    headers: {
      ...headers,
      "X-XSRF-TOKEN": cookies["XSRF-TOKEN"],
    },
  };
});

const logoutLink = onError(({ networkError }) => {
  if (
    networkError?.statusCode === 403 ||
    networkError?.statusCode === 401 ||
    networkError?.statusCode === 302
  ) {
    window.location.href = "/login";
  }
});

const client = new ApolloClient({
  link: logoutLink.concat(authLink).concat(uploadLink),
  cache: new InMemoryCache(),
});

function MyApp({ Component, pageProps }) {
  const { pathname, asPath } = useRouter();
  const { user } = useUser({});

  if (pageProps.protected && !user) {
    return <div>Loading...</div>;
  }

  if (pageProps.protected && !user.authenticated) {
    document.cookie =
      "previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = `previousUrl=${encodeURIComponent(asPath)}; path=/;`;
    window.location.href = "/login";
    return null;
  }

  if (pageProps.protected && user.authenticated) {
    if (
      pageProps.authorities &&
      !pageProps.authorities?.some((pageAuthority) =>
        user?.roles
          ?.map((role) => role.code)
          ?.flat()
          ?.includes(pageAuthority)
      )
    ) {
      // 기능 확인을 위한 임시 비활성화
      /*
            return (
                <div>접근권한이 없습니다.</div>
            );
             */
    }
  }

  const getLayout = Component.getLayout || ((page) => page);

  return getLayout(
    <UserContext.Provider value={user}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src 'self' 'unsafe-eval' 'unsafe-inline' https:;
             connect-src 'self' http://chub.local.kr https://chub.local.kr https://vod.alpha-campus.kr https://search.alpha-campus.kr/xdsl https://lrspoc.alpha-campus.kr/lrs-search/xdsl https://cdn-alpha-campus-kr.akamaized.net https://www.google-analytics.com;
             img-src 'self' data: blob: https:;
             font-src 'self' data: https:;
             frame-src *;
             media-src *;"
        />
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=G-LDBJZR0X4Q`}/>
        <script
          dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'G-LDBJZR0X4Q', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Head>
      <ThemeProvider theme={kirdTheme}>
        <CssBaseline />
        <ConfirmationServiceProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              TransitionComponent={Grow}
            >
              <ApolloProvider client={client}>
                <AdminAuthProvider>
                  <Component {...pageProps} />
                </AdminAuthProvider>
              </ApolloProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ConfirmationServiceProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default appWithTranslation(MyApp);
